<template>
  <div class="solicitacoes-list">
    <v-card-title class="text-h4">
      <v-layout justify-space-between>
        {{ title }}
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :header-props="headerProps"
          :items="request.data"
          :search="tableSearch"
          :loading="request.status === 1"
          fixed-header
          item-key=""
          disable-pagination
          hide-default-footer
          class="scroll-horizontal"
      >
        <template #item.lead="{item}">
          {{ item.lead.cpf }}
        </template>
        <template #item.createdAt="{item}">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template #item.valorEmprestimo="{item}">
          {{ item.valorEmprestimo | currency('R$', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </template>
        <template #item.valorParcela="{item}">
          {{ item.valorParcela | currency('R$', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </template>
        <template #item.tipoStatus="{item}">
          {{ item.status[0].tipoStatus.nome }}
        </template>
        <template #item.status="{item}">
          {{ item.status[0].tipoStatus.descricao}}
        </template>
        <template #item.acoes="{item}">
          <v-layout>
            <details-modal :data="item">
              <template #default="{on, attrs}">
                <v-btn icon color="blue lighten-2" class="mr-4" v-on="on" v-bind="attrs">
                  <v-icon title="detalhes">mdi-card-text</v-icon>
                </v-btn>
              </template>
            </details-modal>
<!--            <form-modal :data="item">-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="editar">mdi-pencil</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </form-modal>-->
<!--            <delete-modal>-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon class="ml-1" color="error" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="excluir">mdi-delete</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </delete-modal>-->
          </v-layout>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
// import DeleteModal from "@/components/modals/delete-modal";
// import FormModal from "@/views/database/solicitacoes/form";
import DetailsModal from "@/views/database/solicitacoes/details";
export default {
  components: {
    // FormModal,
    // DeleteModal,
    DetailsModal
  },
  data: () => ({
    title: "Solicitações",
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Data/Hora", value: "createdAt" },
      { text: "CPF", value: "lead" },
      { text: "Empréstimo", value: "valorEmprestimo" },
      { text: "Parcela", value: "valorParcela" },
      { text: "Status", value: "tipoStatus" },
      { text: "Detalhe Status", value: "status" },
      { text: "Ações", value: 'acoes' },
    ],
  }),
  computed: {
    request() {
      return this.$store.state.database.request;
    }
  },
  mounted() {
    this.$store.dispatch('database/request/getAll', {page: this.$route.query.page ? this.$route.query.page : '1'})
  },
  methods: {
    mergeData(dataA, dataB) {
      return {...dataA, ...dataB}
    },
  }
}
</script>

<style lang="scss">

</style>
